import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiFetchMe} from '@api/user/account/me'
import {useLoginStore} from '@store/LoginStore'
import {IMe} from '@type/profile'
import {isNotEmpty} from '@util/strings'

const useQueryFetchMe = (intervalTime?: number, enabled: boolean = true) => {
    const id = useLoginStore(state => state.id)
    return useQuery({
        queryKey: QueryCacheKeys.account.getMe(id),
        enabled: enabled && isNotEmpty(id),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        refetchInterval: intervalTime ?? false,
        keepPreviousData: true,
        retry: true,
        retryDelay: 2000,
        queryFn: async () => {
            const {data} = await apiFetchMe()
            if (data) return data
            return {} as IMe
        },
    })
}

export default useQueryFetchMe
