import {useRouter} from 'next/router'
import {useLoginModalStore} from '@store/LoginModalStore'
import {equalString} from '@util/strings'

const useLoginModal = () => {
    const router = useRouter()
    const {redirectUrl, setRedirectUrl} = useLoginModalStore(state => ({
        redirectUrl: state.redirectUrl,
        setRedirectUrl: state.setRedirectUrl,
    }))

    const redirectAfterLogin = () => {
        if (redirectUrl && redirectUrl?.length > 0) {
            router?.push(redirectUrl)
            setRedirectUrl(undefined)
        }

        if (equalString(router?.pathname, '/') && !redirectUrl) {
            router?.push(`/leaderboard`)
            setRedirectUrl(undefined)
        }
    }

    return {redirectAfterLogin}
}

export default useLoginModal
